export default rails_ajax_with_saving_emission = (eventTarget, method, url, data, dataType, callback) -> 
  eventTarget.dispatchEvent(new CustomEvent('ajax:send', { bubbles: true }))
  
  Rails.ajax
    type: method
    url: url
    data: data
    dataType: dataType
    complete: ->
      eventTarget.dispatchEvent(new CustomEvent('ajax:complete', { bubbles: true }))
      callback() if callback