import { Controller } from "stimulus"

export default class extends Controller
  @values =
    editSettingsUrl: String

  hover: ->
    @element.classList.add('newsletter-component--hover')

  leave: ->
    @element.classList.remove('newsletter-component--hover')

  loadSettings: (event) ->
    if @editSettingsUrlValue
      @element.dispatchEvent(new CustomEvent('loadingSettings', 
        { 
          bubbles: true
        }
      ))
      
      unless window.currentManageComponentId == @element.dataset.componentId
        Rails.ajax
          type: 'GET'
          url: @editSettingsUrlValue
          dataType: 'script'
          complete: =>
            window.currentManageComponentId = @element.dataset.componentId