import { Controller } from "stimulus"

export default class extends Controller
  initialize: ->
    # Set the links `remote` data attribute to false. Rails UJS bindings are high up in the chain and will
    # be invoked before this controller's #load method. By forcing data-remote to false, we prevent Rails UJS's
    # behavior so we can perform it manually in this controller's #modalOpen method.
    @element.setAttribute('data-remote', false)

  load: (event) ->
    event.preventDefault()

    options = {}

    if @data.get('modifier')
      options.modifier = @data.get('modifier')

    # Create a Remodal instance with the loading content inside.
    @modalInstance = $(JST["templates/loading"]({})).remodal(options)

    # Set up the opened hook.
    @modalInstance.$modal.on("opened", => @modalOpen())

    # Set up the closing and closed hooks.
    @modalInstance.$modal.on("closing", => @modalClosing())
    @modalInstance.$modal.on("closed", => @modalClosed())
    @_additionalCallbacks()

    # Show the modal
    @modalInstance.open()

  _additionalCallbacks: ->
    # ...

  _ajaxSuccessCallbacks: (data) ->
    # ...

  _ajaxErrorCallbacks: (data) ->
    # ...

  modalOpen: ->
    # Now use Rails UJS to fire the ajax request. Rails UJS will handle injecting and executing the
    # response in the event RJS is returned.
    Rails.ajax
      url: @data.get('href') || @element.href
      type: 'GET'
      dataType: 'script'
      success: (data) => @_ajaxSuccessCallbacks(data)
      error: (data) => @_ajaxErrorCallbacks(data)

  # Fired when the modal is closing. Overwrite this in subclasses to do operation
  # specific behavior.
  modalClosing: ->
    # ...

  # Fired when the modal is closed. Overwrite this in subclasses to do operation
  # specific behavior.
  modalClosed: ->
    @modalInstance.destroy()