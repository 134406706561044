import { Controller } from "stimulus"
import _each from 'lodash/each'
import _isEmpty from 'lodash/isEmpty'

export default class extends Controller
  @targets = ['form', 'errors', 'empty', 'list', 'comment']
  
  initialize: ->
    @render()
  
  render: ->
    if @hasCommentTargets
      @emptyTarget.classList.remove('hidden')
    else
      @emptyTarget.classList.add('hidden')
  
  cleanForm: ->
    @clearFormErrors()
  
  addNewComment: (event) ->
    [data, status, xhr] = event.detail
    @clearFormErrors()
    
    if data.errors.length
      errorContent = JST["templates/error_message_block"]
        errors: data.errors
        model: 'comment'
        action: 'posted'

      @setFormErrors(errorContent, data.fields)
    
    else
      @formTarget.reset()
      @listTarget.insertAdjacentHTML 'beforeend', data.html
      @render()
    
  removeComment: (event) ->
    [data, status, xhr] = event.detail
    
    if data.success
      _each @commentTargets, (target) =>
        if "#{target.dataset.id}" is "#{data.comment_id}"
          target.remove()
      
      @render()

  setFormErrors: (content, fields) ->
    @errorsTarget.innerHTML = content
    @errorsTarget.classList.remove('hidden')
    
    _each fields, (fieldName) =>
      field = @element.querySelector("[name$='[#{fieldName}]']")
      if field
        field.closest('.form-group').classList.add('has-error')
  
  clearFormErrors: ->
    _each @element.querySelectorAll('.has-error'), (element) =>
      element.classList.remove('has-error')
    @errorsTarget.classList.add('hidden')